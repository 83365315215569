<h2 mat-dialog-title>Terms & Conditions of MobbSignWeb Demo</h2>
<mat-dialog-content class="mat-typography">
  <h3>Identifying information</h3>
  <p>The terms and conditions indicated below (<em>General Conditions</em>), regulate access, navigation, download and use of the site <u>https://demo.mobbsign.mobbeel.com/</u> (<em>Website</em>) this domain being property of Mobbeel Solutions, SL. These General Conditions also regulate the access, download and use of any other information and/or documents that can be accessed from the Website or from any other web page or computer application (<em>Contents</em>).</p>

  <p>In compliance with the provisions of article 10 of Law 34/2002, of July 11, on information society services and electronic commerce, Mobbeel Solutions, SL (<em>COMPANY</em>) makes available of its users the following registration information of the owner of the Website and/or Contents:</p>

  <ul>
    <li>Company name: Mobbeel Solutions, SL</li>
    <li>CIF: B10395911</li>
    <li>C/ Santa Cristina, S/N, Garaje 2.0, Oficinas 107-109, Cáceres, 10195, Cáceres, Spain</li>
    <li>Phone number: +34 927 209 242</li>
    <li>Contact: you can contact the COMPANY by regular mail to the address of the registered office or through the following email address: <a href="mailto:info@mobbeel.com">info&#64;mobbeel.com</a></li>
  </ul>

  <h3>Website object</h3>

  <p>The aim of this Website is to provide a demonstration of the use of electronic signature of documents using the MobbSign Web tool, owned by the Company. Users may identify themselves on the Website using credentials provided by the Company, upload a document, sign it electronically using MobbSign Web and download the signed document for demonstration purposes only.</p>

  <h3>Website and Contents Conditions of Use</h3>

  <p>The use of this Website is restricted exclusively to demonstration purposes. It is not allowed to use it for productive or commercial purposes. Electronic signing of actual documents or generation of legally binding documents is not allowed. Use of the Website that may provide direct or indirect benefits to the user or third parties is not allowed.</p>

  <p>The Company reserves the rights to, if it deems appropriate, alter the Content hosted on the Website and/or provided by the user to prevent its commercial or productive uses.</p>

  <p>If the user does not accept these General Conditions, the user must refrain from accessing the Website and/or the Contents or, if they have accessed, leave them.</p>

  <p>If the user accepts these General Conditions, he is accepting the electronic signature process of the documents provided, always for demonstration purposes and without any legal or contractual validity.</p>

  <h3>Intellectual property</h3>

  <p>All materials and information on the Website and/or the Company Contents are subject to current regulations on Intellectual and/or Industrial Property. The rights over the materials and other elements displayed on the Website and/or in the Contents (including, by way of example and without limitation, drawings, texts, graphics, photographs, audio, video, software, distinctive signs, etc.) belong to the Company or, where applicable, to third parties who have consented to their transfer to the Company. Likewise, the logos, trade names, domains and brands owned by it belong to the Company.</p>

  <p>The information that the user may provide to the Website and/or the Company Content (documents) must respect the image and intellectual property rights (if any) thereof, with the user being uniquely responsible for any claim that may exist against the Company as a consequence of the use and broadcasting of said materials and information.</p>

  <h3>Personal data protection</h3>

  <p>The Company will provide the user with a username and a password that uniquely and enough identification for full access to the Website and its Contents.</p>

  <p>The access codes assigned will be personal and non-transferable, and transfer, even temporary, to third parties is not allowed. In this sense, the user undertakes to make diligent use and keep secret the token and assigned username to access the Website and/or the Contents. In the event that the user knows or suspects the loss, theft or use of his password by third parties, he must inform the Company of such circumstance as soon as possible.</p>

  <p>The Company reserves the rights to deny at any time and without prior notice the user's access to the Website and/or the Contents by withdrawing their access credentials.</p>

  <h3>Cookies use</h3>

  <p>Neither the Website nor access to the Contents use cookies. Optionally, some browsers may use a session cookie, called <em>JSESSIONID</em>, to keep the state of the user's session while the user navigates the Website. These cookies are temporary and are deleted when you close the browser. No personal data is collected directly through these cookies.</p>

  <h3>Exclusion of liability</h3>

  <p>The use of this Website is the exclusive responsibility of the user.</p>

  <p>The user agrees to use the services, information and materials of the Website and/or the Company Contents in accordance with the Law and these General Conditions. In no case may the use of the Website and/or the Contents by the user may violate current legislation, morality, good practices and public order, and must at all times make correct and lawful use of the services, information and materials of the Website and/or the Contents.</p>

  <p>The user will be able to:</p>

  <ul>
    <li>Access and browse the materials and services of the Website and/or the Contents, without prejudice to the requirement of prior identification and acceptance of General Conditions.</li>
    <li>Use the services and materials of the Website and/or the Contents for exclusively demonstrative use, never commercial or productive purposes.</li>
  </ul>

  <p>Under no circumstances may the user carry out the following activities:</p>

  <ul>
    <li>Upload content or propaganda of a racist, xenophobic, pornographic, obscene, degrading nature or that incites or promotes the commission of criminal, violent, defamatory or degrading acts based on age, sex, religion or beliefs; or that makes, promotes or incites directly or indirectly the apology of terrorism or that is contrary to human rights and the fundamental rights and freedoms of third parties, to current legislation, morality, good customs and public order, or with harmful purposes that may harm, damage or prevent in any way, access to them, to the detriment of the Company or third parties.</li>
    <li>Carry out acts contrary to the Intellectual and/or Industrial Property rights of their legitimate owners.</li>
    <li>Cause damage to the computer systems of the Company, its suppliers or third parties and/or introduce or spread computer viruses, harmful code or software or other types of systems that may cause damage or alterations to computer systems, or unauthorized alterations of the contents, programs or systems accessible through the materials or services of the Website and/or the Contents, or in the information systems, files and computer equipment of the users thereof, or unauthorized access to any materials and services of the Website and/or the Contents.</li>
    <li>Use the Website and/or the Contents, in whole or in part, to promote, sell, hire, disclose advertising or information of your own or third parties.</li>
  </ul>

  <p>The User will respond to the Company, or to third parties, for any damages of any kind that may be caused as a result of non-compliance or non-observance, direct or indirect, of these General Conditions. The Company will ensure at all times compliance with the current legal system, and reserves the rights to deny at its discretion totally or partially, at any time and without prior notice, the access of any user to the Website and/or the Contents, when one or more circumstances described in this clause occur.</p>

  <h3>General Conditions updates</h3>

  <p>The Company may unilaterally and without prior notice modify the provision, configuration, content and services of the Website and the Contents, as well as its General Conditions. If these General Conditions are replaced by others in whole or in part, said new general conditions will be understood to have been accepted in an identical manner to the one stated. However, the user of the Website and/or the Contents must access these General Conditions of the services of the Website and/or the Contents that they use, periodically to be aware of their updates, if the had.</p>

  <h3>Jurisdiction and applicable law</h3>

  <p>These terms and conditions are governed and interpreted in accordance with the laws of the Spanish state. The user irrevocably submits to the exclusive jurisdiction of the courts of this jurisdiction. For any questions or concerns you can contact the Company through the contact channels enabled.</p>

  <p><strong>Last update: March 4, 2024</strong></p>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
