import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import MobbSign from '@mobbeel/mobbsign';
import { AuthService } from '../service/auth.service';
import { MobbsignService } from '../service/mobbsign.service';
import { Auth } from '../auth/model/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-mobbsign',
  templateUrl: './mobbsign.component.html',
  styleUrls: ['./mobbsign.component.scss'],
})
export class MobbsignComponent implements OnInit {
  documentId: string = '';
  auth: Auth;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private mobbSignService: MobbsignService,
    private snackBar: MatSnackBar
  ) {
    this.auth = { apiKey: '', appId: '' };
  }

  ngOnInit() {
    // Get the document id
    this.route.paramMap.subscribe((params) => {
      if (params.has('document-id')) {
        this.documentId = params.get('document-id')!;
      } else {
        this.router.navigate(['/', 'upload-document']);
      }
    });

    // Get auth data
    this.auth = this.authService.getAuth();

    // Show MobbSign server data
    this.mobbSignService.version(this.auth).subscribe({
      next: (version) =>
        console.log(
          `MobbSign Server info: version: ${version.version}, build: ${version.build}, time: ${version.time}`
        ),
      error: (e) => console.error(`error getting server version: ${e}`),
    });

    // Start MobbSign viewer
    new MobbSign({
      root: document.getElementById('mobbsign'),
      endpoint: environment.apiUrl,
      apiKey: this.auth.apiKey,
      appID: this.auth.appId,
      onDocumentLoaded: () => console.log('document loaded'),
      onSignatureAcquired: () => console.log('signature acquired'),
      onSignatureDiscarded: () => console.log('signature discarded'),
      onSignatureConfirmed: () => console.log('signature confirmed'),
      onDocumentSigned: () => console.log('document signed'),
      onError: (e: any) => this.onErrorOccurred(e),
      onDone: () => this.finishProcess(),
      configPath: '../../assets/config',
      libPath: '../../assets/lib',
      imgUrl: './../../../assets/img',
      localesUrl: './../../../locales',
      blockNoTactileDevices: false,
    }).then((mobbsign: any) => {
      mobbsign.load(this.documentId);
    });
  }

  finishProcess() {
    console.log('finish process');
    this.mobbSignService.getDocument(this.auth, this.documentId).subscribe({
      next: (getDocumentResponse) => {
        this.mobbSignService
          .getDocumentContent(this.auth, this.documentId)
          .subscribe({
            next: (contentResponse) => {
              // Get content as B64 and pass it as downloaded file to web browser
              const linkSource = `data:application/pdf;base64,${contentResponse.content}`;
              const downloadLink = document.createElement('a');
              const fileName = `${getDocumentResponse.title}_signed.pdf`;
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
              // Show a message
              this.snackBar.open('Process ended successfully', 'Close', {
                duration: 4000,
              });
              // And go to upload-document page
              this.router.navigate(['/', 'upload-document']);
            },
            error: (e) => {
              console.error(
                `mobbsign getdocumentContent() error. status: ${e.status} - ${e.statusText}, error: ${e.error}, message: ${e.message}`
              );
              this.snackBar.open(
                'There was an error downloading the document content',
                'Close',
                {
                  duration: 4000,
                }
              );
              this.router.navigate(['/', 'upload-document']);
            },
          });
      },
      error: (e) => {
        console.error(
          `mobbsign getdocument() error. status: ${e.status} - ${e.statusText}, error: ${e.error}, message: ${e.message}`
        );
        this.snackBar.open(
          'There was an error downloading the document',
          'Close',
          {
            duration: 4000,
          }
        );
        this.router.navigate(['/', 'upload-document']);
      },
    });
  }

  onErrorOccurred(e: any) {
    console.error(`mobbsign error: ${e}`);
    this.snackBar.open('There was an error signing the document', 'Close', {
      duration: 4000,
    });
  }
}
