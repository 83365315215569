import { Injectable } from '@angular/core';
import { Auth } from '../auth/model/auth';
import { Router } from '@angular/router';
import { MobbsignService } from './mobbsign.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth;
  authChange = new Subject<boolean>();

  constructor(
    private router: Router,
    private mobbsignService: MobbsignService,
    private snackBar: MatSnackBar
  ) {
    this.auth = { apiKey: '', appId: '' };
  }

  login(authData: Auth, showErrMessage: boolean) {
    // Connect to MobbSign Server using GET /application request. If response is OK, auth is valid
    const auth = {
      apiKey: authData.apiKey,
      appId: authData.appId,
    };
    this.mobbsignService.application(auth).subscribe({
      next: (applicationResponse) => {
        const { apiKey, appId } = applicationResponse;
        console.log(`User '${apiKey}' is logged`);

        // Save user auth data
        this.auth = { apiKey: apiKey, appId: appId };

        // Emit event
        this.authChange.next(true);

        // Redirect to upload-document page
        this.router.navigate(['/', 'upload-document']);
      },
      error: (e) => {
        console.error(`login error. status ${e.status} (${e.statusText})`);
        if (showErrMessage) {
          const snackMsg: string = e.status === 403 ? 'Invalid credentials' : 'Server error';
          this.snackBar.open(snackMsg , 'Close', {
            duration: 4000,
          });
        }
      },
    });
  }

  isLogged() {
    return this.auth.apiKey.trim() !== '';
  }

  getAuth() {
    return { ...this.auth };
  }
}
