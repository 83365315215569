import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './service/auth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mobbsign-web-demo';

  constructor(private router: Router) {}

  showHome() {
    return this.router.url.includes('/mobbsign/');
  }

  onHomeBtnClicked() {
    this.router.navigate(['/', 'upload-document']);
  }
}
