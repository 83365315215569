<header>
  <img src="./assets/mobbsign-logo.svg" alt="MobbSign logo" class="img img-fluid"/>
</header>
<body class="mat-typography">
  <router-outlet></router-outlet>
</body>
<footer>
  @if (showHome()) {
    <button mat-icon-button color="primary" aria-label="home icon" (click)="onHomeBtnClicked()">
      <mat-icon>home</mat-icon>
    </button>
  }
  <p class="mobbeel-color-primary m-0">
    Do you need more info? <strong><a href="https://developers.mobbeel.com/docs/mobbsign-web/mobbsign-web-sdk-overview.html" target="_blank" class="text-decoration-underline color-mobbeel-primary">Read the docs!</a></strong>
  </p>
</footer>
