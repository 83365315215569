import { Component } from '@angular/core';
import { Subscription, finalize } from 'rxjs';
import { MobbsignService } from '../service/mobbsign.service';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
})
export class UploadDocumentComponent {
  public isShowingSpinner = false;

  constructor(
    private mobbsignService: MobbsignService,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  onLogoutClicked() {
    console.log('onLogoutClicked()');
  }

  onFileSelected(event: any) {
    // Show load spinner
    this.isShowingSpinner = true;

    const file = event.target.files[0];

    // Separate the file name and it extension by the dot
    const fileNameSplitted = file.name.split('.');
    // Remove the last part (the extension)
    fileNameSplitted.pop();
    // Join the other parts, in case the file name has one or more dots
    const fileName = fileNameSplitted.join('.');

    if (file) {
      // Read file
      const reader = new FileReader();
      // Get file content as B64
      reader.onload = (ev) => {
        const fileB64: string = (ev!.target!.result! as string).replace(
          'data:application/pdf;base64,',
          ''
        );
        // Send document to MobbSign-Server-API
        this.mobbsignService
          .addDocument(this.authService.getAuth(), fileB64, fileName)
          .subscribe({
            next: data => {
              console.info(`upload-document success. documentId: ${data.documentId}`);
              this.isShowingSpinner = false;
                this.router.navigate(['/', 'mobbsign', data.documentId]);
            },
            error: e => {
              console.error(`upload-document error. status: ${e.status} - ${e.statusText}, error: ${e.error}, message: ${e.message}`);
              this.snackBar.open('There was an error uploading the document', 'Close', {
                duration: 4000
              });
            }
          });
      };
      reader.readAsDataURL(file);
    }
  }
}
