import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from '../auth/model/auth';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MobbsignService {
  constructor(private httpClient: HttpClient) {}

  addDocument(auth: Auth, documentContent: string, documentTitle: string) {
    // Create headers
    const headers = createHeaders(auth);

    // Create body
    const body = {
      title: documentTitle,
      signatureType: 'BIOMETRIC_WITH_CERTIFICATE',
      owner: 'Mobbeel',
      signers: [
        {
          rol: 'signer',
          name: 'MobbSignWeb Demo',
        },
      ],
      content: documentContent,
    };

    // POST request
    return this.httpClient.post<any>(
      `${environment.apiUrl}/api/v1/documents/`,
      body,
      {
        headers: headers,
      }
    );
  }

  version(auth: Auth) {
    // Create headers
    const headers = createHeaders(auth);

    // GET request
    return this.httpClient.get<any>(`${environment.apiUrl}/api/v1/version`, {
      headers: headers,
    });
  }

  application(auth: Auth) {
    // Create headers
    const headers = createHeaders(auth);

    // GET request
    return this.httpClient.get<any>(
      `${environment.apiUrl}/api/v1/application`,
      {
        headers: headers,
      }
    );
  }

  getDocumentContent(auth: Auth, documentId: string) {
    // Create headers
    const headers = createHeaders(auth);

    // GET request
    return this.httpClient.get<any>(
      `${environment.apiUrl}/api/v1/documents/${documentId}/content`,
      {
        headers: headers,
      }
    );
  }

  getDocument(auth: Auth, documentId: string) {
    // Create headers
    const headers = createHeaders(auth);

    // GET request
    return this.httpClient.get<any>(
      `${environment.apiUrl}/api/v1/documents/${documentId}`,
      {
        headers: headers,
      }
    );
  }
}

function createHeaders(auth: Auth) {
  return new HttpHeaders({
    'Content-Type': 'application/json',
    API_KEY: auth.apiKey,
    APP_ID: auth.appId,
  });
}
