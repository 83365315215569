import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import packageJson from '../../../package.json';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  loginForm: FormGroup;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router
  ) {
    this.loginForm = new FormGroup({});
  }

  ngOnInit() {
    console.log(`mobbsign-web-demo version: ${packageJson.version}, built time: ${packageJson.builttime}`);
    console.log(`mobbsign-web version: ${packageJson.dependencies['@mobbeel/mobbsign']}`);

    this.loginForm = new FormGroup({
      txtApiKey: new FormControl('', {
        validators: [Validators.required],
      }),
      txtAppId: new FormControl('', {
        validators: [Validators.required],
      }),
      chkAgreeTermsAndConditions: new FormControl(false, {
        validators: [Validators.requiredTrue],
      }),
    });

    // Check if user is logged
    if (this.authService.isLogged()) {
      // if true, redirect to upload-document
      this.router.navigate(['/', 'upload-document']);
    } else {
      // Check if a JSESSIONID cookie is active, trying to connect with fake credentials. If it is succeed, the session cookie is active and user will be redirected to upload-document page
      this.authService.login({ apiKey: 'fake', appId: 'fake' }, false);
    }
  }

  onSubmit() {
    this.authService.login(
      {
        apiKey: this.loginForm.value.txtApiKey,
        appId: this.loginForm.value.txtAppId,
      },
      true
    );
  }

  showTermsAndConditionsDialog() {
    this.dialog.open(TermsAndConditionsComponent);
  }
}
