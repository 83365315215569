<main>
  <section class="container flex-column justify-content-center pt-4">
    <input type="file" class="file-upload" (change)="onFileSelected($event)" accept=".pdf, .PDF" #fileUpload />
    <div class="text-align-center">
      <p>Press the button and select the PDF document to sign</p>
      <div class="mt-3">
        <button mat-stroked-button color="warn" class="btnupload" (click)="fileUpload.click()">Upload</button>
      </div>
    </div>
    <div class="container justify-content-center pt-4">
      @if (isShowingSpinner) {
        <mat-spinner diameter="30"></mat-spinner>
      }
    </div>
  </section>
</main>
