<main class="vh-80">
  <section id="title" class="container justify-content-center">
    <h1>Web Demo</h1>
  </section>
  <section class="container mt-3 justify-content-center align-items-center">
    <form class="container flex-column justify-content-center align-items-center" style="margin-block-end: 0!important" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>Client</mat-label>
        <input type="text" matInput formControlName="txtApiKey" autofocus autocomplete="username"/>
        <mat-error>Required value</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="pt-1">
        <mat-label>Token</mat-label>
        <input type="password" matInput formControlName="txtAppId" autocomplete="current-password"/>
        <mat-error>Required value</mat-error>
      </mat-form-field>
      <div class="text-align-center pt-1">
        <mat-checkbox color="primary" formControlName="chkAgreeTermsAndConditions">I accept the &nbsp;</mat-checkbox>
        <span class="clickable" (click)="showTermsAndConditionsDialog()">Terms & Conditions of MobbSignWeb Demo</span>
      </div>
      <button mat-raised-button class="mt-3" color="primary" type="submit" [disabled]="loginForm.invalid">Submit</button>
    </form>
  </section>
</main>
