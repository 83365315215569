import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { MobbsignComponent } from './mobbsign/mobbsign.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TermsAndConditionsComponent } from './welcome/terms-and-conditions/terms-and-conditions.component';
import { AuthService } from './service/auth.service';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { HttpClientModule } from '@angular/common/http';
import { MobbsignService } from './service/mobbsign.service';

@NgModule({
  declarations: [
    AppComponent,
    MobbsignComponent,
    WelcomeComponent,
    TermsAndConditionsComponent,
    UploadDocumentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [AuthService, MobbsignService],
  bootstrap: [AppComponent],
})
export class AppModule {}
